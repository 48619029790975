import Vue from 'vue'
import Router from 'vue-router'
import TicketForm from '@/components/TicketForm'


Vue.use(Router)

export let router = new Router({
  base: 'user',
  routes: [{
      path: '/ticket',
      name: 'ticket',
      component: TicketForm,
      meta: {
        auth: true,
        title: 'Ticket'
      }
    },
    {
      path: '/',
      name: 'empty-path',
      component: TicketForm,
      meta: {
        auth: true,
        title: 'Ticket'
      }
    }
  ]
})
