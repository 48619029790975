<template>
  <v-app id="inspire">
    <v-main ref="mainContainer">
      <router-view></router-view>

    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    active: false,
    dialog: false,
    drawer: null,
    mini: false,
  }),
  methods: {
  },
  computed: {
    title() {
      return this.$route.meta.title || 'Tickets'
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.mDivider {
  border-width: thin 0 thin 0 !important;
  border-color: white !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mTable tr:nth-of-type(even) {
  background-color: rgba(0, 0, 255, 0.1);
  border-radius: 10px;
}

.mTable thead {
  background-color: #1565C0;
  color: white !important;
}

.mTable th {
  color: white !important;
}

.mTableNoHeader tr:nth-of-type(even) {
  background-color: rgba(0, 0, 255, 0.1);
}

.invisible {
  visibility: hidden;
}

.greyBackground {
  background-color: #f5f5ff;
}
</style>
