import Api from '@/services/Api'
const path = 'tickets'

export default {
    create(data) {
        return Api().post(
            path,
            data
        )
    },
    getEmployees() {
        return Api().get(
            'employees'
        )
    }
}