<template>
  <v-form ref="form" v-model="valid" style="padding-bottom: 10px;">

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container>

      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-text-field v-model="ticket.name" counter maxlength="100" label="Nome" required clearable
            :rules="[rules.required]"></v-text-field>
        </v-col>

      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-text-field v-model="ticket.company" counter maxlength="100" label="Empresa" required clearable
            :rules="[rules.required]"></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-text-field v-model="ticket.nif" label="NIF" counter maxlength="100" required clearable
            :rules="[rules.required]"></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-text-field v-model="ticket.contact" counter maxlength="100" label="Contacto" required clearable
            :rules="[rules.required]"></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-text-field v-model="ticket.email" counter maxlength="100" label="E-mail" required clearable :rules="emailRules"></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-textarea clearable v-model="ticket.description" counter maxlength="500" label="Descrição do Problema"
            :rules="[rules.required]"></v-textarea>
        </v-col>

      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-combobox clearable v-model="ticket.preferedEmployee" :return-object="false" item-text="name"
            item-value="name" :items="employees" label="Técnico Preferencial"></v-combobox>
        </v-col>

      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="8">

          <v-file-input label="Anexar Ficheiros" chips multiple v-model="files" variant="outlined"
            :rules="[rules.fileSize]"></v-file-input>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="2">
          <v-btn color="success" class="mt-4" block @click="submit" :loading="sending" :disabled="sending">
            Enviar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar.showing" :timeout="6000" :color="snackbar.color">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.showing = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>


    <confirm-dialog ref="confirm" />

  </v-form>
</template>

<script>
import ServiceTickets from "@/services/ServiceTickets";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
export default {
  name: "TicketForm",
  components: {
    ConfirmDialog
  },
  data() {
    return {
      rules: {
        required: value => !!value || 'Campo obrigatório',
        fileSize: value => !value || value.reduce((partialSum, a) => partialSum + a.size, 0) < 20000000 || 'Tamanho máximo dos ficheiros são 20 MB!'
      },
      emailRules: [
        v => !!v || 'Campo obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail inválido',
      ],
      valid: false,
      ticket: {
        name: "",
        company: "",
        nif: "",
        contact: "",
        email: "",
        description: "",
        preferedEmployee: ""
      },
      emptyTicket: {
        name: "",
        company: "",
        nif: "",
        contact: "",
        email: "",
        description: "",
        preferedEmployee: ""
      },
      files: [],
      snackbar: {
        showing: false
      },
      sending: false,
      loading: false,
      employees: []

    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {

          if (!this.validaContribuinte(this.ticket.nif)) {

            if (!await this.$refs.confirm.open("Confirmação",
              `Nif inválido.
            <br><br> Pretende continuar?`, {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              disagreeText: "Não",
              agreeText: "Sim",
            })) {
              return;
            }
          }

          this.sending = true;
          let formData;
          formData = new FormData();

          formData.set("name", this.ticket.name);
          formData.set("company", this.ticket.company);
          formData.set("nif", this.ticket.nif);
          formData.set("contact", this.ticket.contact);
          formData.set("email", this.ticket.email);
          formData.set("description", this.ticket.description);
          formData.set("preferedEmployee", this.ticket.preferedEmployee);

          for (let index = 0; index < this.files.length; index++) {
            formData.append("file", this.files[index]);
          }

          await ServiceTickets.create(formData);

          this.ticket = {};
          // this.imageUrl = "";
          this.$refs.form.reset();
          this.ticket = Object.assign({}, this.emptyTicket);

          this.handleSnackBar();

        } catch (error) {
          console.log(error);
          let msg = '';
          if (error.response) {
            if (error.response.status == 429) {
              msg = "Excedeu o limite de pedidos. Por favor tente mais tarde."
            }
          }
          this.handleSnackBar(msg, 'error');
        }

      }

      this.sending = false;
    },
    validaContribuinte(contribuinte) {
      var temErro = 0;

      if (
        contribuinte.substr(0, 1) != '1' && // pessoa singular
        contribuinte.substr(0, 1) != '2' && // pessoa singular
        contribuinte.substr(0, 1) != '3' && // pessoa singular
        contribuinte.substr(0, 2) != '45' && // pessoa singular não residente
        contribuinte.substr(0, 1) != '5' && // pessoa colectiva
        contribuinte.substr(0, 1) != '6' && // administração pública
        contribuinte.substr(0, 2) != '70' && // herança indivisa
        contribuinte.substr(0, 2) != '71' && // pessoa colectiva não residente
        contribuinte.substr(0, 2) != '72' && // fundos de investimento
        contribuinte.substr(0, 2) != '77' && // atribuição oficiosa
        contribuinte.substr(0, 2) != '79' && // regime excepcional
        contribuinte.substr(0, 1) != '8' && // empresário em nome individual (extinto)
        contribuinte.substr(0, 2) != '90' && // condominios e sociedades irregulares
        contribuinte.substr(0, 2) != '91' && // condominios e sociedades irregulares
        contribuinte.substr(0, 2) != '98' && // não residentes
        contribuinte.substr(0, 2) != '99' // sociedades civis

      ) { temErro = 1; }
      var check1 = contribuinte.substr(0, 1) * 9;
      var check2 = contribuinte.substr(1, 1) * 8;
      var check3 = contribuinte.substr(2, 1) * 7;
      var check4 = contribuinte.substr(3, 1) * 6;
      var check5 = contribuinte.substr(4, 1) * 5;
      var check6 = contribuinte.substr(5, 1) * 4;
      var check7 = contribuinte.substr(6, 1) * 3;
      var check8 = contribuinte.substr(7, 1) * 2;

      var total = check1 + check2 + check3 + check4 + check5 + check6 + check7 + check8;
      var divisao = total / 11;
      var modulo11 = total - parseInt(divisao) * 11;

      var comparador;
      if (modulo11 == 1 || modulo11 == 0) {
        comparador = 0;
      } // excepção
      else {
        comparador = 11 - modulo11;
      }

      var ultimoDigito = contribuinte.substr(8, 1) * 1;
      if (ultimoDigito != comparador) { temErro = 1; }

      if (temErro == 1) {
        return false;
      } else {
        return true;
      }
    },
    blobToData(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
      })
    },
    handleSnackBar(text, color) {
      if (color === 'error') {
        this.snackbar.text = text || 'Ocorreu um erro. Por favor tente novamente.';
      } else {
        this.snackbar.text = text || 'Operação efectuada com sucesso.';
      }
      this.snackbar.color = color || 'success';

      this.snackbar.showing = true;
    }
  },
  async mounted() {
    this.loading = true;
    try {
      this.loading = true;
      this.employees = (await ServiceTickets.getEmployees()).data;
    } catch (error) {

    }
    this.loading = false;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
